import React from 'react'
import CallToAction from 'src/components/CallToAction'
import { SideBar, ContentWrapper } from 'src/layouts/BaseLayout/style'

// Images
import BigShopping from 'src/assets/images/temps/inter-seguros/big-shopping.png'
import CeltaEngenharia from 'src/assets/images/temps/inter-seguros/celta-engenharia.png'
import Cobimex from 'src/assets/images/temps/inter-seguros/cobimex.png'
import CollemConstrutora from 'src/assets/images/temps/inter-seguros/collem-construtora.png'
import GvShopping from 'src/assets/images/temps/inter-seguros/gv-shopping.png'
import MinasCasa from 'src/assets/images/temps/inter-seguros/minas-casa.png'
import MrlEngenharia from 'src/assets/images/temps/inter-seguros/mrl-engenharia.png'
import MrvEngenharia from 'src/assets/images/temps/inter-seguros/mrv-engenharia.png'
import Ponteio from 'src/assets/images/temps/inter-seguros/ponteio.png'
import Tamasa from 'src/assets/images/temps/inter-seguros/tamasa.png'
import Urbamais from 'src/assets/images/temps/inter-seguros/urbamais.png'
import Zigma from 'src/assets/images/temps/inter-seguros/zigma.png'

export const Content = () => (
  <>
    <ContentWrapper>
      <section className='pt-5 pb-lg-5 bg-white'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col col-lg-8'>
              <h3>Seguros com a qualidade que você já conhece do Banco Inter</h3>
              <p>Praticidade é ter todos os serviços que precisa no seu banco de confiança. Para oferecer ainda mais conveniência e variedade de produtos pra você, o Banco Inter incorporou em 2012 ao seu hub de negócios a Fronting Consultoria em Seguros que se tornou Inter Seguros.</p>
              <p>Com produtos para pessoas física e jurídica, a Inter Seguros está pronta para atender desde as suas necessidades mais comuns até as mais específicas, com a mesma parceria de seu banco 100% digital.</p>
              <ul className='list-unstyled'>
                <li><a className='link-arrow-left' href='/pra-voce/seguros/'> Conheça nossos Seguros Pessoa Física</a></li>
                <li><a className='link-arrow-left' href='/empresas/seguros/'> Conheça nossos Seguros Pessoa Jurídica</a></li>
              </ul>
              <p>
                <strong>Qualidade e simplicidade</strong><br />
                Em parceria com as maiores seguradoras do mercado em cada segmento e operação 100% digital, os melhores seguros estão na palma da sua mão.
              </p>
              <p>
                <strong>Atendimento especializado</strong><br />
                Time especializado que formata o pacote de seguros ideal para a sua família ou para a sua empresa, de acordo com as suas necessidades.
              </p>
              <p>
                <strong>Gestão de Apólice</strong><br />
                Cuidamos de todas questões de apólice pra você, desde o recebimento até a conferência e a renovação.
              </p>
              <p>
                <strong>Gestão de sinistros</strong><br />
                Registramos, acompanhamos e auxiliamos os clientes durante todo o processo de regulação de sinistros.
              </p>
              <div className='col-12 px-0 mt-3 my-lg-5'>
                <h4 className='mb-4'>Clientes Inter Seguros</h4>
                <div className='row'>
                  <div className='col-6 col-md-4 col-lg-3'><img src={BigShopping} alt='Big Shopping' /></div>
                  <div className='col-6 col-md-4 col-lg-3'><img src={CeltaEngenharia} alt='Celta Engenharia' /></div>
                  <div className='col-6 col-md-4 col-lg-3'><img src={Cobimex} alt='Cobimex' /></div>
                  <div className='col-6 col-md-4 col-lg-3'><img src={CollemConstrutora} alt='Collem Construtora' /></div>
                  <div className='col-6 col-md-4 col-lg-3'><img src={GvShopping} alt='GV Shopping' /></div>
                  <div className='col-6 col-md-4 col-lg-3'><img src={MinasCasa} alt='Minas Casa' /></div>
                  <div className='col-6 col-md-4 col-lg-3'><img src={MrlEngenharia} alt='MRL Engenharia' /></div>
                  <div className='col-6 col-md-4 col-lg-3'><img src={MrvEngenharia} alt='MRV Engenharia' /></div>
                  <div className='col-6 col-md-4 col-lg-3'><img src={Ponteio} alt='Ponteio' /></div>
                  <div className='col-6 col-md-4 col-lg-3'><img src={Tamasa} alt='Tamasa' /></div>
                  <div className='col-6 col-md-4 col-lg-3'><img src={Urbamais} alt='Urbamais' /></div>
                  <div className='col-6 col-md-4 col-lg-3'><img src={Zigma} alt='Zigma' /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='container pb-4'>
        <SideBar className='col-12 col-lg-4 px-0 px-lg-3'>
          <div className='position-sticky'>
            <CallToAction
              section='dobra_2'
              elementAction='click button'
              elementName='Abra sua conta'
              sectionName='Seguros com a qualidade que você já conhece do Banco Inter'
              redirectUrl='/pra-voce/conta-digital/pessoa-fisica/'
              text='Abra sua conta'
              link='/pra-voce/conta-digital/pessoa-fisica/'
            />
          </div>
        </SideBar>
      </div>
    </ContentWrapper>
  </>

)
